import React, { useEffect, useState } from "react";
import useColor from "../hooks/useColor";
import Contact from "./modals/ContactCard";
import moment from "moment";
import data from "../db";
import {
  ActionCTAs,
  Bottom,
  BottomLeft,
  BottomRight,
  ContactMe,
  Container,
  Explore,
  Top,
  TopLeft,
  TopRight,
} from "./styles/styles-landing";
import {
  ModalButtons,
  ModalMessage,
  ModalMessageContent,
} from "./styles/styles-contactMe";
import { Nav } from "react-bootstrap";
import { SocialIcon } from "react-social-icons";
import ContactForm from "./utils/ContactForm";

const skills = [
  "Java",
  "C++",
  "NodeJs",
  "Javascript",
  "CSS",
  "HTML",
  "PHP",
  "Spring Boot",
  "Spring MVC",
  "Spring Security",
  "React",
  "Docker & Kubernetes",
  "Spring Cloud",
  "Git",
  "TDD",
  "Amazon Web Services",
  "Jira",
  "Jenkins",
  "MongoDB",
  "MySQL",
];

const Landing = () => {
  const { colors } = useColor();
  const [show, setShow] = useState(false);
  const [subscribe, setSubscribe] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  const handleShowModal = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    setSubscribe(!subscribe);
  };

  useEffect(() => {
    data?.projects?.sort(
      (a, b) => new Date(b.updatedOn) - new Date(a.updatedOn)
    );
    setLastUpdated(data?.projects[0]);
  });

  return (
    <Container background={colors?.background}>
      <Bottom background={colors?.dark} bgImage={lastUpdated?.cover}>
        <BottomLeft>
          <div>
            <Explore
              background={colors?.dark}
              color={colors?.background}
              to="/projects"
            >
              Explore
            </Explore>
            <span>All the projects + blog posts</span>
          </div>
          <div>
            <h3>{lastUpdated?.title}</h3>
          </div>
        </BottomLeft>
        <BottomRight background={colors?.dark} color={colors?.background}>
          <h4>{moment(Date.now()).format("dddd")}</h4>
          <p>{moment(Date.now()).format("LL")}</p>

          <p>No updates</p>
        </BottomRight>
      </Bottom>

      <Top>
        <TopLeft>
          <ActionCTAs>
            <ContactMe
              background={colors?.dark}
              color={colors?.background}
              onClick={handleShowModal}
            >
              Contact Me
            </ContactMe>

            <ContactMe
              color={colors?.dark}
              onClick={handleSubscribe}
            >
              Subscribe to the project chanel
            </ContactMe>
          </ActionCTAs>
        </TopLeft>
        <TopRight color={colors?.dark}>
          {data?.stats?.map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex flex-column justify-content-center align-items-center "
              >
                <h3>{item?.statistics}</h3>
                <p>{item?.name}</p>
              </div>
            );
          })}
        </TopRight>
      </Top>

      {show && (
        <Contact closeButton={handleShowModal}>
          
          <ModalMessageContent><ContactForm/>
            {/* <ModalMessage color={colors?.background}>
              <h5>Email: othniel.idohou@gmail.com </h5>
              <h5>Email: nemetoninc@gmail.com</h5>
            </ModalMessage> */}
          </ModalMessageContent>
          <ModalButtons>
            <Nav>
              <div className="d-flex justify-content-end">
                <div className="p-2 bd-highlight">
                  <SocialIcon
                    target="_blank"
                    url="https://github.com/oidohou"
                    style={{ height: 44, width: 44 }}
                  />
                </div>
                <div className="p-2 bd-highlight">
                  <SocialIcon
                    target="_blank"
                    url="http://instagram.com/neonscale"
                    style={{ height: 44, width: 44 }}
                  />
                </div>
                <div className="p-2 bd-highlight">
                  <SocialIcon
                    target="_blank"
                    url="https://www.linkedin.com/in/othniel-idohou-1746499a/"
                    style={{ height: 44, width: 44 }}
                  />
                </div>
              </div>
            </Nav>
          </ModalButtons>
        </Contact>
      )}

      {subscribe && (
        <Contact closeButton={handleSubscribe}>
          <ModalMessageContent>
            <ModalMessage color={colors?.dark}>
              <h4>Coming Soon ... </h4>
            </ModalMessage>
          </ModalMessageContent>
        </Contact>
      )}
    </Container>
  );
};
export default Landing;
