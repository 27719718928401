import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

class Navigation extends React.Component {
  render() {
    return (
      <div style={{ maxHeight: "30px",marginBottom: "1rem" }}>
        <Navbar collapseOnSelect expand="lg" >
          <Navbar.Brand href="/" className="branding">
            Othniel I.
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"  />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-2 ml-auto">
              <Nav.Link
                as={NavLink}
                eventKey="1"
                activeClassName="active-link"
                className="nav-link"
                exact
                to="/"
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                eventKey="2"
                activeClassName="active-link"
                className="nav-link"
                to="/projects"
              >
                Projects
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                eventKey="3"
                activeClassName="active-link"
                className="nav-link"
                to="/aboutme"
              >
                About
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                eventKey="4"
                activeClassName="active-link"
                className="nav-link"
                to="/resume"
              >
                Digital resume
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Navigation;
