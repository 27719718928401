import styled from "styled-components";

export const ModalOverlay = styled.div`
  background-color: rgba(216, 216, 216, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: end;
  color: ${({ color }) => (color ? color : "rgba(126, 116, 166, 1)")};
`;
export const ClocseButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2c2c2c;
  cursor: pointer;
  color: #fff;
`;

export const ModalWrapper = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "rgba(126, 116, 166, 1)"};
  border-radius: 1rem;
  box-shadow: 0 0 16px 0 hsl(0deg 0% 84% / 50%);
  min-width: 50vw;
  min-height: 50vh;
  padding: 1rem;
  position: fixed;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  @media (max-width: 768px) {
    min-width: 75vw;
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const ModalMessageContainer = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ModalMessageContent = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ModalMessage = styled.p`
  color: ${({ color }) => (color ? color : "rgba(126, 116, 166, 1)")};
  font-size: 1rem;
  margin: 0;
`;
