import React from "react";
import { Route } from "react-router-dom";
import Landing from "./landingpage";
import About from "./aboutme";
import Contact from "./contact";
import Projects from "./projects";
import Resume from "./resume";
import ProjectDetail from "./projectDetails";

const Main = () => (
  <React.Fragment>
    <Route exact path="/" component={Landing} />
    <Route path="/aboutme" component={About} />
    <Route path="/contact" component={Contact} />
    <Route path="/projects" component={Projects} />
    <Route path="/resume" component={Resume} />
    <Route path="/project/:id-:title" component={ProjectDetail} />
  </React.Fragment>
);
export default Main;
