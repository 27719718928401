import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import useColor from "../../hooks/useColor";
import { Menu } from "../styles/styles-mobileMenu";
import { CiUser, CiHome, CiSquareInfo, CiCircleList } from "react-icons/ci";

const Mobilemenu = ({}) => {
  const { colors } = useColor();
  return (
    <Menu background={colors?.dark}>
      <Nav.Link
        as={NavLink}
        activeClassName="active-link"
        className="nav-link"
        exact
        to="/"
      >
        <CiHome size={24} color={colors.background} />
      </Nav.Link>
      <Nav.Link
        as={NavLink}
        activeClassName="active-link"
        className="nav-link"
        to="/projects"
      >
        <CiCircleList size={24} color={colors.background} />
      </Nav.Link>
      <Nav.Link
        as={NavLink}
        activeClassName="active-link"
        className="nav-link"
        to="/aboutme"
      >
        <CiSquareInfo size={24} color={colors.background} />
      </Nav.Link>
      <Nav.Link
        as={NavLink}
        activeClassName="active-link"
        className="nav-link"
        to="/resume"
      >
        <CiUser size={24} color={colors.background} />
      </Nav.Link>
    </Menu>
  );
};
export default Mobilemenu;
