import React, { createContext } from "react";

const ColorContext = createContext({});

export const ColorProvider = ({ children }) => {
  const colors = {
    background: "#FFF",
    light: "#D0CDD7",
    regular: "#ACB0BD",
    lightDark: "#4B6377",
    dark: "#2c2c2c",
  };

  return (
    <ColorContext.Provider value={{ colors }}>{children}</ColorContext.Provider>
  );
};

export default ColorContext;
