import React, { Component, useEffect, useState } from "react";
import data from "../db";
import moment from "moment";
//import RelatedProjectCard from "./utils/relatedproject";
import { ButtonToolbar, Button, Row, Col, Spinner } from "react-bootstrap";
import {
  DescriptionContainer,
  DescriptionContent,
  DetailContainer,
  Extras,
  Skills,
  Stacks,
  TagList,
  TitleContainer,
} from "./styles/styles-projects";
import useColor from "../hooks/useColor";

const ProjectDetail = (props) => {
  const [details, setDetails] = useState({});
  const [tags, setTags] = useState([]);
  const [paragraphs, setParagraphs] = useState(null);
  const { colors } = useColor();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });

  useEffect(() => {
    const { id } = props.match.params;
    const projectDetails = data.projects.filter((p) => p.id === Number(id))[0];
    const categories = projectDetails.categories.map((x) => {
      return x.toUpperCase();
    });
    setDetails({ ...projectDetails });
    setTags([...categories]);
  }, []);

  useEffect(() => {
    const listStartIndex = details?.desc?.indexOf("-");
    const listEndIndex = details?.desc?.lastIndexOf("-");
    let text = details?.desc?.split("\n").map((paragraph, index) => {
      if (paragraph.startsWith("\t")) {
        return <h3>{paragraph.trim().slice(0)}</h3>;
      } else if (paragraph.startsWith("*")) {
        return <h5>{paragraph.trim().slice(1)}</h5>;
      } else if (
        index === 0 ||
        index < listStartIndex ||
        index > listEndIndex
      ) {
        return (
          <div>
            <p>{paragraph.trim()}</p>
          </div>
        );
      } else {
        return <li>{paragraph.trim().slice(1)}</li>;
      }
    });
    setParagraphs(text);
  }, [details]);
  if (isLoading) {
    return (
      <div className="App d-flex flex-column justify-content-center align-items-center">
        <Spinner animation="border"/>
      </div>
    );
  }
  return (
    <DetailContainer>
      <TitleContainer background={colors?.dark} color={colors?.background}>
        <h1 className="text-uppercase pt-2">{details?.title}</h1>
        <p>{moment(details?.updatedOn).format("ll")}</p>
      </TitleContainer>

      <DescriptionContainer>
        <DescriptionContent
          background={colors?.background}
          color={colors?.dark}
        >
          {paragraphs}
        </DescriptionContent>
        <Extras color={colors?.dark}>
          <a
            href={details.githubLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Available on GitHub
          </a>
          <Stacks background={colors?.dark} color={colors?.background}>
            <h4>Tech stack</h4>
            <TagList>
              {tags.map((tag, i) => (
                <Button key={i} variant="outline-light" size="sm" disabled>
                  {tag}
                </Button>
              ))}
            </TagList>
          </Stacks>
          <Skills background={colors?.light} color={colors?.dark}>
            <h4>Skills acquired in this project</h4>
            {details?.skills?.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </Skills>
        </Extras>
      </DescriptionContainer>

     
    </DetailContainer>
  );
};

export default ProjectDetail;
