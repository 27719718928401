import React from "react";
import useColor from "../../hooks/useColor";
import {
  CloseButton,
  ModalMessageContainer,
  ModalOverlay,
  ModalWrapper,
  ClocseButtonIcon,
} from "../styles/styles-contactMe";

const Contact = ({ closeButton, children }) => {
  const { colors } = useColor();

  return (
    <ModalOverlay>
      <ModalWrapper backgroundColor={colors?.background}>
        <CloseButton color={colors?.background} onClick={closeButton}>
          <ClocseButtonIcon>X</ClocseButtonIcon>
        </CloseButton>
        <ModalMessageContainer>{children}</ModalMessageContainer>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default Contact;
