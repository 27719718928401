import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding: 1rem 0px;
  background-color: ${({ background }) =>
    background ? background : "transparent"};

  
`;

export const Top = styled.div`
  flex: 1 1 300px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
 
`;

export const TopLeft = styled.div`
  flex: 1 1 300px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
`;
export const TopRight = styled.div`
  flex: 1 1 80px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0.5rem 1rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  color: ${({ color }) => (color ? color : "transparent")};
  @media (max-width: 768px) {
    display: none
  }
`;

export const ContactMe = styled.button`
  width: 350px;
  padding: 0.5rem;
  border-radius: 4px;
  min-height: 3.2rem;
  max-height: 3.5rem;
  border-style: none;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  color: ${({ color }) => (color ? color : "transparent")};
  border-style: ${({ background }) => (!background ? "solid" : "none")};
  border-width: ${({ background }) => (!background ? "1px" : 0)};
  border-color: ${({ background, color }) => (!background ? color : "none")};

  &:hover {
    background-color: ${({ background, color }) => !background && color};
    color: ${({background }) => (!background && "#fff")};
    border-style: ${({ background }) => (!background ? "solid" : "none")};
    border-width: ${({ background }) => (!background ? "1px" : 0)};
    border-color: ${({ background, color }) => (!background ? color : "none")};
  }
`;

export const Bottom = styled.div`
  flex: 1 1 90vh;
  margin: 0.5rem;
  border-radius: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.5rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};

  background-image: ${({ bgImage }) => bgImage && `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    gap:0.5rem;
  }
`;

export const BottomLeft = styled.div`
  flex: 10 1 300px;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  @media (max-width: 768px) {
    flex: 1 ;
    flex-direction: column;
    font-size: 0.8rem;
  }
`;
export const BottomRight = styled.div`
  width: 200px;
  height: 200px;
  max-width: 45vw;
  max-height: 50vh;
  margin: 1rem;
  border-radius: 10px;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  color: ${({ color }) => (color ? color : "transparent")};
  @media (max-width: 768px) {
    display: none
  }
`;

export const Explore = styled(NavLink)`
  padding: 0.5rem 2rem !important;
  margin-right: 0.5rem;
  border-radius: 25px;
  min-height: 3.2rem;
  max-height: 3.5rem;
  border-style: none;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  color: ${({ color }) => (color ? color : "transparent")} !important;

  &:hover {
    text-decoration: none;
    text-tranform: none;
  }
`;

export const ActionCTAs = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
  padding: 0.5rem 1rem;
  flex-wrap: wrap;
`;
