import React, { Component } from "react";
import { Jumbotron, Container } from "react-bootstrap";

class Resume extends Component {
  render() {
    return (
      <div className="flex-grow-1  container d-flex">
        <div className="align-self-center flex-grow-1 m-2">
          <h1>Professional experience (Canada)</h1>
          <Jumbotron fluid>
            <Container>
              <div>
                <h3>Full stack Web Developer Nov 2021 - Present</h3>
                <ul>
                  <li>
                    Design, develop, and maintain software applications
                    utilizing Java 8 and React with TypeScript
                  </li>
                  <li>
                    Collaborate with cross-functional teams to ensure that
                    software meets all requirements and is delivered on schedule
                  </li>
                  <li>
                    Participate in the full software development life cycle,
                    including coding, debugging, testing, and deployment
                  </li>
                  <li>Write clean, maintainable, and efficient code</li>
                  <li>
                    Continuously strive to stay current with the latest
                    advancements in software engineering
                  </li>
                  <li>Troubleshoot and debug applications</li>
                  <li>Ensure code quality through testing and code reviews</li>
                  <li>
                    Participate in the design and development of scalable and
                    maintainable architecture
                  </li>
                  <li>
                    Work with stakeholders to understand their requirements and
                    deliver solutions that meet their needs.
                  </li>
                </ul>
              </div>
              <div>
                <h3>Intermediate Web Developer Feb 2021 - Nov 2021</h3>
                <ul>
                  <li>
                    Design, develop, and maintain software applications using
                    Node.js and React.js
                  </li>
                  <li>
                    Collaborate with cross-functional teams to ensure that
                    software meets all requirements and is delivered on schedule
                  </li>
                  <li>
                    Participate in the full software development life cycle,
                    including coding, debugging, testing, and deployment
                  </li>
                  <li>Write clean, maintainable, and efficient code</li>
                  <li>Troubleshoot and debug applications</li>
                  <li>Ensure code quality through testing and code reviews</li>
                  <li>
                    Participate in the design and development of scalable and
                    maintainable architecture
                  </li>
                  <li>
                    Work with stakeholders to understand their requirements and
                    deliver solutions that meet their needs.
                  </li>
                  <li>
                    Experience with web technologies such as HTML, CSS, and
                    JavaScript.
                  </li>
                  <li>
                    Familiarity with modern front-end frameworks and libraries.
                  </li>
                  <li>Strong understanding of API design and development.</li>
                  <li>Experience with version control systems such as Git.</li>
                  <li>Experience with database technologies such as MySQL.</li>
                </ul>
              </div>
              <div>
                <h3>Software Engineer APRIL 2019 - JAN 2020</h3>
                <ul>
                  <li>
                    Design, develop, and maintain software applications
                    utilizing Java 8 and React with TypeScript
                  </li>
                  <li>
                    Collaborate with cross-functional teams to ensure that
                    software meets all requirements and is delivered on schedule
                  </li>
                  <li>
                    Participate in the full software development life cycle,
                    including coding, debugging, testing, and deployment
                  </li>
                  <li>Write clean, maintainable, and efficient code</li>
                  <li>
                    Continuously strive to stay current with the latest
                    advancements in software engineering
                  </li>
                  <li>Troubleshoot and debug applications</li>
                  <li>Ensure code quality through testing and code reviews</li>
                  <li>
                    Participate in the design and development of scalable and
                    maintainable architecture
                  </li>
                  <li>
                    Work with stakeholders to understand their requirements and
                    deliver solutions that meet their needs
                  </li>
                </ul>
              </div>
              <div>
                <h3>Software Analyst Oct 2017 - JAN 2019</h3>
                <ul>
                  <li>
                    Analyze, design and maintain software systems to meet
                    business requirements
                  </li>
                  <li>
                    Work closely with other members of the IT team to understand
                    business requirements and translate them into technical
                    solutions
                  </li>
                  <li>
                    Identify and evaluate software requirements, and design,
                    develop, test, and maintain software applications
                  </li>
                  <li>
                    Analyze and optimize existing systems and provide support to
                    end-users
                  </li>
                  <li>
                    Ensure compliance with industry standards and identify and
                    mitigate risks
                  </li>
                  <li>
                    Provide guidance and recommendations to the development team
                  </li>
                  <li>
                    Communicate effectively with all technical and non-technical
                    staff
                  </li>
                  <li>
                    Use software development methodologies and tools to improve
                    development processes and productivity
                  </li>
                  <li>
                    Keep up-to-date with the latest trends, technologies, and
                    best practices in software development and analysis.
                  </li>
                  <li>
                    Have good understanding of software development life cycle
                    and software quality assurance methodologies.
                  </li>
                  <li>
                    Ability to work independently and collaboratively as part of
                    a team.
                  </li>
                  <li>Strong analytical and problem-solving skills.</li>
                  <li>Strong written and verbal communication skills.</li>
                  <li>
                    Ability to work with different stakeholders to gather and
                    understand requirements.
                  </li>
                </ul>
              </div>
            </Container>
          </Jumbotron>
        </div>
      </div>
    );
  }
}
export default Resume;
