import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Menu = styled.div`
  display: none;
  @media (max-width: 768px) {
    height: 60px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 900;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    padding:0.5rem;
    background-color: ${({ background }) =>
      background ? background : "transparent"};
  }
`;
