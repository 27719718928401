import React, { useState } from "react";
import useColor from "../../hooks/useColor";
import { ContactMe } from "../styles/styles-landing";

const ContactForm = () => {
  const { colors } = useColor();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add your own logic here to handle form submission
    console.log("Form submitted:", formData);
  };

  return (
    <div
      style={{ flexGrow: 1, display: "flex", flexDirection: "column", gap: 2 }}
    >
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <label htmlFor="email">Email:</label>
        <input
          className="form-control"
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <label htmlFor="name">Subject:</label>
        <input
          className="form-control"
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
          style={{width:'300px'}}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <label htmlFor="message">Message:</label>
        <textarea
          className="form-control"
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows="4"
          required
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "flex-start",
          gap: 2,
          margin: " 1rem 0rem",
        }}
      >
        {/* <ContactMe color={colors?.dark} onClick={handleSubmit}> */}
          <a
          as={ContactMe}
            href={`mailto:oidohou@klozbi.ca?subject=${formData.subject}&body=${formData.message}`}
          >
            Click to Send your Message
          </a>
        {/* </ContactMe> */}
      </div>
    </div>
  );
};

export default ContactForm;
