import React from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ProjectCard = ({ data }) => {
  return (
    <Col sm="12" md="4" lg="4" className="mt-3 mb-2 projects-content">
      <Card>
        <Card.Img variant="top" src={data.cover} />
        <Card.Body>
          <Link
            className="projectTitle"
            to={"/project/" + data.id + "-" + data.title}
          >
            <Card.Title>{data.title}</Card.Title>
          </Link>
          <div className="mt-3">
            <footer className="blockquote-footer">
              <cite title="Source Title">By {data.postby}</cite>
              <cite title="Source Title" className="ml-2">
                {data.updatedOn}
              </cite>
            </footer>
            <Card.Text
              className="mt-2"
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical"
              }}
            >
              {data.desc}
            </Card.Text>
          </div>
          <Link
            className="readmore"
            to={"/project/" + data.id + "-" + data.title}
          >
            Read more
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default ProjectCard;
