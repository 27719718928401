import React, { Component } from "react";
import { Form, Col, Button } from "react-bootstrap";

class Contact extends Component {
  render() {
    return (
      <div className="flex-grow-1  container d-flex ">
        <div className="align-self-center flex-grow-1">
          <div className="d-flex flex-wrap justify-content-center mb-3">
            <div className="p-2 text-center ">
              <i className="fab fa-skype fa-3x mb-3" />
              <p>Skype: Slim07ace</p>
            </div>
            <div className="p-2 text-center">
              <i className="fas fa-envelope fa-3x mb-3" />
              <p>Mail: nemetoninc@gmail.com </p>
            </div>
            <div className="p-2 text-center">
              <i className="fas fa-phone fa-3x mb-3" />
              <p>Cellphone: +1 (647)-989-9787</p>
            </div>
          </div>
          <div className="d-flex flex-column  mb-3">
            <div>
              <Form>
                <Form.Row>
                  <Form.Group
                    as={Col}
                    sm={12}
                    lg={6}
                    controlId="formGridTitle "
                  >
                    <Form.Control placeholder="Your name" />
                  </Form.Group>
                  <Form.Group as={Col} sm={12} lg={6}>
                    <Form.Control placeholder="Your email address" />
                  </Form.Group>
                </Form.Row>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Type your message ..."
                  />
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Button variant="secondary" size="lg" block type="submit">
                    Send my message
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Contact;
