import React from "react";
import Masonry from "react-masonry-component";

class MasonryCards extends React.Component {
  render() {
    const masonryOptions = {
      transitionDuration: 0,
    };

    const imagesLoadedOptions = { background: ".my-bg-image-el" };
    return (
      <Masonry
        className={"my-gallery-class"} // default ''
        options={masonryOptions} // default {}
        disableImagesLoaded={false} // default false
        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        imagesLoadedOptions={imagesLoadedOptions}
      >
        {this.props.children}
      </Masonry>
    );
  }
}

export default MasonryCards;
