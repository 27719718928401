import React from "react";
import useColor from "../hooks/useColor";
import {
  DescriptionContainer,
  DescriptionContent,
  DetailContainer,
  Extras,
  FixedContent,
  Stacks,
  TagList,
} from "./styles/styles-projects";
import data from "../db";
import { Button } from "react-bootstrap";

const About = () => {
  const { colors } = useColor();
  return (
    <DetailContainer>
      <DescriptionContainer>
        <DescriptionContent
          background={colors?.background}
          color={colors?.dark}
        >
          <h1> About me</h1>
          <FixedContent>
            <p>
              Hello and welcome to my "About Me" page. My name is Othniel H.
              Idohou, and I am a full stack developer with almost a decade of
              experience in the field. I am passionate about developing
              high-quality, scalable, and reliable software solutions that solve
              real-world problems.
            </p>
            <p>
              I first became interested in programming while studying computer
              science at the university. I was fascinated by the idea of
              building complex systems from scratch and solving problems through
              code. After graduating, I started my career as a back-end
              developer, working on projects for various clients in industries
              such as e-commerce, different APIs.
            </p>
            <p>
              As I gained more experience, I realized that I wanted to expand my
              skillset and become a full stack developer. I wanted to have a
              deeper understanding of the entire development process, from
              front-end to back-end, and be able to develop and deploy complete
              applications. So, I enrolled in a full stack development bootcamp,
              where I learned new technologies and frameworks such as React,
              Node.js,Ruby on rails, and MongoDB.
            </p>
            <p>
              Since then, I have worked on a variety of projects as a full stack
              developer. One of my most challenging and rewarding projects was
              developing a custom Saas platform for a small business. The
              platform needed to handle a large inventory of products and
              orders, integrate with multiple payment gateways, and provide a
              seamless user experience for customers. I worked closely with the
              client to understand their needs and requirements and delivered a
              solution that exceeded their expectations.
            </p>
            <p>
              Another project I am proud of is a healthcare application that I
              developed for hospitals. The application allowed doctors and
              nurses to manage patient information, schedules, and appointments
              in a secure and efficient way. The application also integrated
              with existing hospital systems and complied with strict privacy
              and security regulations.
            </p>
            <p>
              As a full stack developer, I am skilled in a variety of
              programming languages and frameworks. On the front-end, I am
              proficient in HTML, CSS, and JavaScript, and I have experience
              working with libraries and frameworks such as React. On the
              back-end, I am experienced in languages such as Java, and
              JavaScript, and I have worked with frameworks such as Node.js,
              Ruby on rails. I am also proficient in database management and
              deployment, with experience in MySQL, PostgreSQL, MongoDB, and
              AWS.
            </p>
            <p>
              I believe that communication and collaboration are key to
              successful software development projects. That's why I place a
              strong emphasis on working closely with clients and colleagues to
              understand their needs and requirements. I believe that software
              development is not just about writing code but about solving
              real-world problems and delivering value to users. By working
              collaboratively, we can ensure that the final product meets the
              needs of all stakeholders.
            </p>
            <p>
              In addition to my technical skills, I am committed to writing
              clean, well-documented code that is easy to maintain and extend. I
              believe that code quality is essential to the success of any
              software project, and I take pride in delivering high-quality code
              that meets industry standards.
            </p>
            <p>
              When I'm not coding, you can find me pursuing my hobbies and
              interests. I enjoy hiking and exploring the outdoors,taking
              pictures. I believe in maintaining a healthy work-life balance and
              taking time to recharge and pursue my passions outside of work.
            </p>
            <p>
              Thank you for taking the time to learn more about me. If you have
              any questions or would like to discuss a project, please don't
              hesitate to get in touch. I am always open to new opportunities
              and challenges, and I look forward to hearing from you.
            </p>
          </FixedContent>
        </DescriptionContent>
        <Extras color={colors?.dark}>
          {data?.aboutStack?.map((stack, index) => (
            <Stacks
              background={colors?.dark}
              color={colors?.background}
              key={index}
            >
              <h1>{stack?.category}</h1>
              <TagList>
                {stack?.technologies?.map((tech, i) => {
                  return (
                    <Button key={i} variant="outline-light" size="sm" disabled>
                      {tech}
                    </Button>
                  );
                })}
              </TagList>
            </Stacks>
          ))}
        </Extras>
      </DescriptionContainer>
    </DetailContainer>
  );
};
export default About;
