import styled from "styled-components";

export const ProjectContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

/** Project details*/

export const DetailContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 3rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
  border-radius: 0.2rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  color: ${({ color }) => (color ? color : "transparent")};
`;

export const DescriptionContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  border-radius: 1rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  color: ${({ color }) => (color ? color : "transparent")};
`;

export const DescriptionContent = styled.div`
  flex: 3 1 600px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  color: ${({ color }) => (color ? color : "transparent")};
`;
export const Extras = styled.div`
  flex: 1 1 300px;
  gap: 1rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  color: ${({ color }) => (color ? color : "transparent")};
`;

export const Stacks = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  gap: 1rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  color: ${({ color }) => (color ? color : "transparent")};
`;
export const TagList = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const Skills = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  gap: 1rem;
  background-color: ${({ background }) =>
    background ? background : "transparent"};
  color: ${({ color }) => (color ? color : "transparent")};
`;

export const FixedContent = styled.div`
  height: 75vh;
  overflow-y: scroll;
  border-radius: 0.4rem;
  padding: 1rem;
`;
