import React, { Component } from "react";
import Masonry from "./utils/masonry";
import ProjectCard from "./project";
//import LoadingDots from './utils/loadingDots';
import data from "../db";
import { Form } from "react-bootstrap";
import { ProjectContainer } from "./styles/styles-projects";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectList: data.projects,
      filtered: [],
      filterSelected: null,
    };
  }

  componentDidMount() {
    let filter = [];
    data.projects.map((category, index) => {
      filter = filter.concat(category.categories);
      return "";
    });
    filter = [...new Set(filter)];
    filter = filter.map((x) => {
      return x.toUpperCase();
    });

    this.setState({ filtered: filter });
  }

  handleCategory = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({
      filterSelected: event.target.value,
      projectList: data.projects,
    });
    const dataFiltered = [];

    data.projects.map((projects, index) => {
      projects.categories = projects.categories.map((x) => {
        return x.toUpperCase();
      });
      if (projects.categories.includes(event.target.value)) {
        dataFiltered.push(projects);
      }
      this.setState({ projectList: dataFiltered });
      return "";
    });
  };

  render() {
    const { projectList, filtered } = this.state;

    const projectsList = projectList.map((project, index) => (
      <ProjectCard key={index} data={project} />
    ));
    return (
      <ProjectContainer>
        <div className="projects-content flex-grow-1 d-flex flex-column">
          <div className="d-flex ">
            <div className="flex-grow-1 p-2"></div>
            <div className=" p-2">
              <select
                className="form-select"
                defaultValue={null}
                value={this.state.filterSelected}
                onChange={this.handleCategory}
                name="filter"
                style={{border:"none" }}
              >
                <option name="filtre" value="all" defaultValue={"all"} >
                  -- Please select a filter --
                </option>
                {filtered.map((value, index) => {
                  return (
                    <option name="filtre" key={index} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <Masonry className="flex-grow-1 ">{projectsList}</Masonry>
        </div>
      </ProjectContainer>
    );
  }
}
export default Projects;
