import React, { Component, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "./App.css";
import Main from "./components/main";
import Mobilemenu from "./components/utils/MobileMenu";
import Navigation from "./components/utils/navigation";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });
  if (isLoading) {
    return (
      <div className="App d-flex flex-column justify-content-center align-items-center">
        <Spinner animation="border" />
      </div>
    );
  }
  return (
    <div
      className="App d-flex flex-column"
      style={{ position: "relative"}}
    >
      <Navigation />
      <Main />
      <Mobilemenu />
    </div>
  );
};

export default App;
